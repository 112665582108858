.price-card-content {
    list-style: none;
    padding-left: 0;
  }

  .price-card-content li {
    position: relative;
    padding-right: 25px; 
    padding-top: 16px;
    /* Provide space for the icon on the right */
  }

  /* Style for checked items */
  .price-card-content li.checked::after {
    content: '\2713'; /* Unicode checkmark */
    position: absolute;
    right: 0; /* Position the icon to the right of the text */
    color: green; /* Adjust color as needed */
  }

  /* Style for unchecked items */
  .price-card-content li.unchecked::after {
    content: '\2717'; /* Unicode cross mark, you can change it */
    position: absolute;
    right: 0; /* Position the icon to the right of the text */
    color: red; /* Adjust color as needed */
  }